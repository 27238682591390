import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './Home'
import RedirectPage from './RedirectPage'
import './App.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='*'>
          <RedirectPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
