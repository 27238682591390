import PropTypes from 'prop-types'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firebase } from './config'

const SlugTable = ({ limit, title, type }) => {
  let query
  if (type === 'top') {
    query = firebase.firestore().collection('url')
      .where('isPrivate', '==', false)
      .orderBy('visitedCount', 'desc')
      .limit(limit)
  } else if (type === 'recent') {
    query = firebase.firestore().collection('url')
      .where('isPrivate', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(limit)
  } else {
    query = firebase.firestore().collection('url')
      .where('isPrivate', '==', false)
      .limit(limit)
  }

  const [data, loading, error] = useCollectionData(query)

  return (
    <div className='url-table'>
      <h2>{title} {limit}</h2>
      {loading && (<div><i className='fa fa-spinner fa-2x spin' /></div>)}
      {data && data.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Count</th>
              <th>Slug</th>
            </tr>
          </thead>
          <tbody>
            {data.map(d => (
              <tr key={d.slug} onClick={() => window.open(`/${d.slug}`, '_blank')}>
                <td>{d.visitedCount}</td>
                <td>{d.slug}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {error && <h3>Oops, failed to fetch!</h3>}
    </div>
  )
}

SlugTable.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

SlugTable.defaultProps = {
  limit: 10
}

export default SlugTable
