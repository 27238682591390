import SlugTable from './SlugTable'

const SlugTables = () => {
  return (
    <div className='url-table-container'>
      <SlugTable title='Top' type='top' />
      <SlugTable title='Recent' type='recent' />
    </div>
  )
}

export default SlugTables
