import Header from './Header'
import CreateSlugForm from './CreateSlugForm'
import SlugTables from './SlugTables'

const Home = () => {
  return (
    <div className='App'>
      <Header />
      <main>
        <CreateSlugForm />
        <SlugTables />
      </main>
    </div>
  )
}

export default Home
