import { useLocation, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { firebase } from './config'

const RedirectPage = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      const doc = firebase.firestore().collection('url').doc(location.pathname.slice(1))
      const data = await doc.get()

      if (data.exists) {
        await doc.update({ visitedCount: firebase.firestore.FieldValue.increment(1) })
        window.location.href = data.data().url
      } else {
        history.push('/')
      }
    }

    fetchData()
  }, [location, history])

  return <div />
}

export default RedirectPage
