import { useState } from 'react'
import { firebase } from './config'

const CreateSlugForm = () => {
  const [url, setUrl] = useState('')
  const [slug, setSlug] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      setError(null)
      setResult(null)
      setLoading(true)
      await firebase.firestore().collection('url').doc(slug).set({
        slug,
        url,
        isPrivate,
        visitedCount: 0,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      setLoading(false)
      setResult(slug)
      setUrl('')
      setSlug('')
      setIsPrivate(false)
    } catch (error) {
      setLoading(false)
      setError('Error! Could not save your url.')
    }
  }

  const toggleIsPrivate = () => {
    setIsPrivate(!isPrivate)
  }

  const createWaveLabel = (label, offset, interval) => {
    return label
      .split('')
      .map((character, index) =>
        <span
          key={index}
          style={{ transitionDelay: `${offset + index * interval}ms` }}
          className={character === ' ' ? 'space' : ''}
        >
          {character}
        </span>
      )
  }

  return (
    <>
      <form className='form' onSubmit={onFormSubmit}>
        <div className={`form-control${slug ? ' move-placeholder' : ''}`}>
          <input data-testid='slug-input' type='text' name='slug' value={slug} onChange={e => setSlug(e.target.value)} autoComplete='off' />
          <div className='wave-label'>
            {createWaveLabel('Create a slug', 0, 30)}
          </div>
        </div>
        <div className={`form-control${url ? ' move-placeholder' : ''}`}>
          <input data-testid='url-input' type='url' name='url' value={url} onChange={e => setUrl(e.target.value)} autoComplete='off' />
          <div className='wave-label'>
            {createWaveLabel('Create an url', 0, 30)}
          </div>
        </div>
        <div className='checkbox-container'>
          <input id='isPrivate' type='checkbox' checked={isPrivate} onChange={toggleIsPrivate} />
          <label htmlFor='isPrivate'>
            <div className='circle' />
          </label>
          <span className='label'>Private</span>
        </div>
        <button type='submit' disabled={loading || !slug || !url}>Submit{loading && <i className='fa fa-spinner' />}</button>
      </form>
      {result && (
        <div className='result'>
          {result && <a href={`/${result}`} target='_blank' rel='noreferrer'>{result}</a>}
        </div>
      )}
      {error && (
        <div className='error'>
          {error}
        </div>
      )}
    </>
  )
}

export default CreateSlugForm
