import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCG_dUw5pOhOLxqURbkSd3BgiynrgFvrzs',
  authDomain: 'url-short-428e9.firebaseapp.com',
  databaseURL: 'https://url-short-428e9.firebaseio.com',
  projectId: 'url-short-428e9',
  storageBucket: 'url-short-428e9.appspot.com',
  messagingSenderId: '959448609314',
  appId: '1:959448609314:web:34e830624dfe067a56107b'
}

firebase.initializeApp(firebaseConfig)

if (window.location.hostname === 'localhost') {
  firebase.firestore().useEmulator('localhost', 8080)
}

export { firebase }
